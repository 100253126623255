import { LoaderFunctionArgs } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { AvailableRegionType } from "~/libs/i18n";
import { ErrorBoundary as _ErrorBoundary } from "~/root";
import { LocaleInputType } from "~/zeus";

export const loader = async ({
  request,
  params,
}: LoaderFunctionArgs & {
  params: {
    locale: LocaleInputType;
    region: AvailableRegionType;
    path: string | string[];
  };
}) => {
  throw new Response("Not Found", { status: 404 });
};

export default function NotFoundRoute() {
  return <Outlet />;
}

export const ErrorBoundary = _ErrorBoundary;
